import React, {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {setChatToken} from "../../../../modules/actions/setChatToken";
import axiosInstance from "../../../../modules/util/axiosInstance";
import MarkdownPreview from '@uiw/react-markdown-preview';
import {setChatSetting} from "../../../../modules/actions/setChatSetting";
import ButtonCheck from "../../../atoms/Button/Button.check";
import "./_style.scss"

const ChatContent = () => {
    const dispatch = useDispatch();
    const [settingModal, setSettingModal] = useState(false);

    const chatSetting = useSelector(state => state.chatSetting);
    const userInfo = useSelector(state => state.userInfo);
    const chatToken = useSelector(status => status.chatToken);
    const endOfMessageRef = useRef();
    const userText = useRef();
    const [messageList, setMessageList] = useState([]);

    // 토큰이 변할때마다 챗 리스트 가져오기
    useEffect(() => {
        (chatToken === "") ? setMessageList([]) : getMessageList();
    }, [chatToken]);

    // 스크롤 이벤트
    useEffect(() => {
        endOfMessageRef.current.scrollTo({
            top: 9999999999,
            behavior: "smooth"
        })
    }, [messageList])

    const getMessageList = async () => {
        try {
            const response = await axiosInstance.get("/api/chat/" + chatToken + "/list", {
                params: {
                    page: 0,
                    size: 100000
                }
            });
            if (response.data.msg === 'success') {
                const responseData = response.data.data;
                setMessageList(responseData?.itemList);
            }
        } catch (error) {
            console.error('Error : ' + error);
        }
    }


    const sendMessage = async () => {
        let botMessage = "";
        let chatInfo = "";
        const sendText = userText.current.value;

        if (sendText.trim() === "") return;

        const newMessageList = [...messageList, {sender: 'user', content: sendText}];
        setMessageList(newMessageList);

        // 생성중 답변
        setMessageList([...newMessageList, {sender: 'assistant', content: "답변 생성 중입니다..."}]);

        // send Input 초기화
        userText.current.value = "";

        const writer = new WritableStream({
            write(chunk) {
                // message 가져오기
                const getMessage = chunk.split("\n").map(data => {
                    if (data.trim() !== "") {
                        const match = data.match(/"message":"(.*?)"/g);
                        return match ? match[0].replace(/"message":"(.*?)"/, '$1') : null;
                    }
                }).join("").replaceAll("\\n", "\n")

                chatInfo = chunk;

                botMessage = botMessage + getMessage;
                setMessageList([...newMessageList, {
                    sender: 'assistant',
                    content: botMessage,
                    cot: "false",
                    hyde: "false",
                    model: ""
                }]);
            },

            close() {
                //config 추가
                const chunkString = chatInfo.split('\n').filter(Boolean);
                const chatInfoData = JSON.parse(chunkString[chunkString.length - 1].replace("data:", ""));
                dispatch(setChatToken(chatInfoData.token));

                setMessageList([...newMessageList, {
                    sender: 'assistant',
                    content: botMessage,
                    cot: chatInfoData.cot.toString() || "false",
                    hyde: chatInfoData.hyde.toString() || "false",
                    model: chatInfoData.model
                }]);
            },
        })

        await fetch('/api/chat/ai/generate/stream', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userInfo.accessToken}`,
                'Accept': '*/*',
            },
            body: JSON.stringify({
                userMessage: sendText,
                token: chatToken,
                model: chatSetting.model,
                hyde: chatSetting.hyde,
                cot: chatSetting.cot
            })
        }).then((res) => {
            res.body.pipeThrough(new TextDecoderStream())
                .pipeTo(writer);
        })

    }


    return (
        <>
            <div className="messages" ref={endOfMessageRef}>
                {messageList.map((message, index) => {
                    if (message.sender === "user") {
                        return (
                            <div key={`user_message_` + index} className="user-message">
                                <div className="user-time">
                                    {moment(message.createDate).format("HH:mm")}
                                </div>
                                <div className="user-text">
                                    <MarkdownPreview source={message.content} style={{padding: 16}}></MarkdownPreview>
                                </div>
                            </div>
                        )
                    } else if (message.sender === "assistant") {
                        return (
                            <div key={`ai_message_` + index} className="ai-message">
                                <img src="/favicon.png" alt=""/>

                                <div className="ai-text">
                                    <MarkdownPreview source={message.content} style={{padding: 16}}/>
                                </div>

                                <div className="ai-time">
                                    {moment(message.createDate).format("HH:mm")}
                                </div>

                                <div className="break"/>

                                <div className="ai-info">
                                    {message.model ? <div>{message.model}</div> : ""}
                                    {message.cot === "true" ? <div>CoT</div> : ""}
                                    {message.hyde === "true" ? <div>hyde</div> : ""}
                                </div>
                            </div>
                        )
                    }
                })}
            </div>

            {/* 채팅 입력란 */}
            <div className="chat-input">
                <i className="ri-settings-2-fill" onClick={() => setSettingModal(!settingModal)}/>
                <input ref={userText} type="text"
                       onKeyUp={(e) => (e.keyCode === 13) ? sendMessage() : ""}
                       placeholder="Type a message..."/>
                <button onClick={() => sendMessage()}>Send</button>
            </div>

            {/* setting modal */}
            <div className="chat-settings" style={settingModal ? {} : {display: "none"}}>
                <div>LLM 선택</div>
                <hr/>
                <div className="llm-select">
                    <div>
                        Hyde
                    </div>
                    <ButtonCheck click={(e) => dispatch(setChatSetting({
                        hyde: e.target.value,
                        cot: chatSetting.cot,
                        model: chatSetting.model
                    }))} list={[{label: "auto", value: "auto"}, {label: "on", value: "true"}, {
                        label: "off",
                        value: "false"
                    }]} checked={chatSetting.hyde}/>
                </div>
                <div className="llm-select">
                    <div>
                        COT
                    </div>
                    <ButtonCheck click={(e) => dispatch(setChatSetting({
                        hyde: chatSetting.hyde,
                        cot: e.target.value,
                        model: chatSetting.model
                    }))} list={[{label: "auto", value: "auto"}, {label: "on", value: "true"}, {
                        label: "off",
                        value: "false"
                    }]} checked={chatSetting.cot}/>
                </div>
                <div>Model 선택</div>
                <hr/>
                <div className="model-select">
                    <div className="model-btn">
                        <div onClick={() => {
                            dispatch(setChatSetting({
                                hyde: chatSetting.hyde,
                                cot: chatSetting.cot,
                                model: "gpt-4o"
                            }))
                        }} style={chatSetting.model === "gpt-4o" ? {backgroundColor: "#007bff", color: "white"} : {}}
                        >gpt-4o
                        </div>
                    </div>
                    <div className="model-btn">
                        <div onClick={() => {
                            dispatch(setChatSetting({
                                hyde: chatSetting.hyde,
                                cot: chatSetting.cot,
                                model: "claude"
                            }))
                        }} style={chatSetting.model === "claude" ? {backgroundColor: "#007bff", color: "white"} : {}}
                        >claude
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ChatContent;