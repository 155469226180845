import React, {useEffect} from 'react';
import {useSelector} from "react-redux";
import {Route, Routes, useNavigate} from "react-router-dom";

import {UserList, FileList, Chat, Setting} from "../../pages";

import "./_style.scss";

const Middle = () => {
    const role = process.env.REACT_APP_ROLE;
    const navigate = useNavigate();
    const location = useSelector(state => state.location);

    useEffect(() => {
        navigate(location)
    }, [location]);

    return (
        <div className="content">
            <Routes>
                {
                    role === "admin" ? (
                        <>
                            <Route path="/" element={<UserList/>}/>
                            <Route path="/file" element={<FileList/>}/>
                            <Route path="/chat" element={<Chat/>}/>
                            <Route path="/setting" element={<Setting/>}/>
                        </>
                    ) : (
                        <>
                            <Route path="/" element={<FileList/>}/>
                            <Route path="/chat" element={<Chat/>}/>
                        </>
                    )
                }
            </Routes>
        </div>
    )
}

export default Middle