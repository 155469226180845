import axios from "axios";
import store from "../store";

// Axios 인스턴스 생성
const axiosInstance = axios.create({
    timeout: 50000, // 타임아웃 설정 (10초)
    headers: {
        'Authorization': `Bearer ${store.getState().userInfo.accessToken}`,
        'Accept': '*/*'
    },
});

// 요청 또는 응답 인터셉터 추가
axiosInstance.interceptors.request.use(
    config => {
        // 요청 전 처리
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);


// 응답 interceptors
axiosInstance.interceptors.response.use(
    response => {
        // 응답 전 처리
        return response;
    },
    error => {
        window.location.href = '/login'
        return Promise.reject(error);
    }
);

export default axiosInstance;