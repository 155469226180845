import React from 'react';
import './_style.scss';
import {useDispatch, useSelector} from "react-redux";
import {isActiveSideMenu} from "../../../modules/actions/isActiveSideMenu";
import {useLocation} from "react-router-dom";
import {setLocation} from "../../../modules/actions/setLocation";

const SideMenu = () => {
    const role = process.env.REACT_APP_ROLE;
    const activeMenu = useSelector(status => status.isActiveSideMenu);
    const dispatch = useDispatch();
    const location = useLocation();

    const movePage = (link) => {
        dispatch(setLocation(link));
    }

    const navList = (role === 'admin') ? [{
        Path: "/",
        Icon: <i className="ri-home-line"></i>,
        Label: "Home"
    }, {
        Path: "/file",
        Icon: <i className="ri-file-line"></i>,
        Label: "File"
    }, {
        Path: "/chat",
        Icon: <i className="ri-chat-1-line"></i>,
        Label: "Chat"
    }, {
        Path: "/setting",
        Icon: <i className="ri-settings-2-line"></i>,
        Label: "Setting"
    }] : [{
        Path: "/",
        Icon: <i className="ri-home-line"></i>,
        Label: "File"
    }, {
        Path: "/chat",
        Icon: <i className="ri-chat-1-line"></i>,
        Label: "Chat"
    }]

    return (
        <div className="sideMenu" style={{
            animation: activeMenu ? "fadeInRight 0.5s" : "fadeOutLeft 0.5s",
            marginLeft: activeMenu ? "0px" : "-170px"
        }}>
            {navList.map((data, idx) => {
                return (
                    <div
                        key={"nav_" + idx}
                        className={(location.pathname.split("/")[1] === data.Path.split("/")[1]) ? "nav_active" : "nav"}
                        onClick={() => movePage(data.Path)}
                    >
                        {data.Icon}
                        <label>{data.Label}</label>
                    </div>
                )
            })}

            <div className="sideMenu_expand" onClick={() => dispatch(isActiveSideMenu())}>
                {
                    activeMenu ?
                        <>
                            <i className="ri-menu-unfold-2-line"></i>
                        </>
                        :
                        <div className="open_side_menu">
                            <i className="ri-menu-unfold-line"></i>
                        </div>
                }
            </div>
        </div>
    )
}

export default SideMenu;