import React from 'react';
// import FileUploadModal from './FileUploadModal/index.js';
import FileTable from "../../molecules/file/FileListTable";
import {isActiveModal, setModal} from "../../../modules/actions/modalAction";
import {useDispatch} from "react-redux";
import FileUploadModal from "../../molecules/file/FileUploadModal";

const FileList = () => {
    const dispatch = useDispatch();
    const openModal = () => {
        dispatch(setModal({
            title: "파일 업로드",
            size: {
                width: 30,
                height: 60
            },
            component: <FileUploadModal/>
        }))
        dispatch(isActiveModal());
    }

    return (
        <div className="FileList">
            <h2>FileList</h2>
            <div className="addFile">
                <i className="ri-upload-2-line" onClick={() => openModal()}/>
            </div>

            <div className="FileListTable">
                <FileTable/>
            </div>
        </div>
    )
}

export default FileList;
