import React from "react";
import ChatList from "../../molecules/chat/ChatList";
import ChatContent from "../../molecules/chat/ChatContent";
import {useSelector} from "react-redux";
import styled from "styled-components";

const Chat = () => {
    const isActiveSideMenu = useSelector(state => state.isActiveSideMenu);

    return (
        <ChatContainer className="chat-container" activemenu={isActiveSideMenu.toString()}>
            <div className="chat-list">
                <ChatList/>
            </div>
            <div className="chat-content">
                <ChatContent/>
            </div>
        </ChatContainer>
    )
}

export default Chat;

const ChatContainer = styled.div`
    width: ${props => props.activemenu === "true" ? 'calc(100% - 170px)' : '100%' };
    
    @media (max-width: 500px) {
        width: 100%
    }
`;

